html {
	/* Scale multiplier, changes based on orientation/aspect ratio to ensure content fits nicely within the viewport */
	--scaleMult: 1;
	-webkit-backface-visibility: hidden; /* Safari */
	backface-visibility: hidden;
}

body {
	overflow: hidden;
	/* Remove default button highlight colours */
	-webkit-tap-highlight-color: transparent;
}

/* Remove 'spin button' from number input: Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Remove 'spin button' from number input: Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

/* Change the look of default placeholder text */
::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: normal;
	font-style: italic;
	color: rgba(0, 47, 70, 0.6);
}
::-moz-placeholder {
	/* Firefox */
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: normal;
	font-style: italic;
	color: rgba(0, 47, 70, 0.6);
}
:-ms-input-placeholder {
	/* IE 10+ */
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: normal;
	font-style: italic;
	color: rgba(0, 47, 70, 0.6);
}

/* -- Background -- */
/* ---------------- */

#backgroundImage {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: fixed;
	background-image: url('Images/Backgrounds/Background1.png');
	background-size: cover;
	background-position: center;
	pointer-events: none;
}

#background {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: #005771;
}

/* -- Views -- */
/* ----------- */

#Default {
	display: none;
}

#Connecting {
	display: none;
}

#Reconnecting {
	display: none;
}

#Lobby {
	display: none;
}

#PlayerTypeSelect {
	display: none;
}

#WaitOrAddPlayers {
	display: none;
}

#WaitOrChoosePackage {
	display: none;
}

#CharacterSelect {
	display: none;
}

#GameInputPhase {
	display: none;
}

#ItemPlacementVote {
	display: none;
}

#WriterTextInput {
	display: none;
}

#LookAtScreen {
	display: none;
}

/* -- Main UI -- */
/* ------------- */

.multiturnHeader {
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	background-color: rgba(0, 47, 70, 0.7);
	backdrop-filter: blur(3vmin);
	border-bottom: calc(0.7vmin * var(--scaleMult)) white solid;
}

#logo {
	height: 80%;
	pointer-events: none;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

#splashLogo {
	width: 70vmin;
	pointer-events: none;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.heartrateLoader {
	width: 40vmin;
	height: 40vmin;
	pointer-events: none;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
	-webkit-mask-image: -webkit-linear-gradient(
		left,
		transparent 0%,
		rgba(0, 0, 0, 1) 10%,
		rgba(0, 0, 0, 1) 90%,
		transparent 100%
	);
	mask-image: linear-gradient(90deg, transparent 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, transparent 100%);
}

#socialLinks {
	position: fixed;
	left: 2vmin;
	bottom: 1.2vmin;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	transform-origin: bottom left;
}

#playerTabsAndMenuLayout {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	left: 0;
	right: 0;
	top: 0;
	transform-origin: top;
	height: 12.5vmin;
	z-index: 1;
}

#playerTabsLayout {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 0vmin;
	height: 20vmin;
	overflow-x: hidden;
	margin-top: -17.5vmin;
	padding-left: 2vmin;
	right: 5vmin;
	pointer-events: none;
}

#menuButtonLayout {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 1vmin;
	height: 20vmin;
	overflow-x: hidden;
	margin-top: -5vmin;
	padding-left: 2vmin;
	padding-right: 2vmin;
}

.anchorBottom {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}

.anchorTop {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
}

#joinPanel {
	background-color: rgba(0, 47, 70, 0.7);
	backdrop-filter: blur(3vmin);
	width: 75vmin;
	border-radius: 5vmin;
	border: 0.7vmin solid white;
	padding-bottom: 3vmin;
	margin-bottom: 1vmin;
}

#directionHeading {
	padding-bottom: 28vmin;
}

#playerNameTop {
	flex-grow: 1;
	flex-basis: 0;
	font-size: 5vmin;
	padding-top: 7.5vmin;
	padding-right: 14vmin;
	height: 7vmin;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	visibility: visible;
}

#playerNameOverflowPanel {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	left: 0;
	right: 0;
	top: calc(12vmin * var(--scaleMult));
	transform-origin: top;
	height: 7vmin;
	text-align: center;
	visibility: visible;
}

#playerNameOverflow {
	font-size: 4.2vmin;
	padding-top: 0.9vmin;
}

#itemCarouselLayout {
	height: 45vmin;
	width: 96vmin;
	clip-path: inset(0vmin);
	position: relative;
}
#itemPositionButtonsLayout {
	width: 96vmin;
}
#itemPositionButtonsPanel {
	width: 96vmin;
}

#characterButtonsPanel {
	width: 93vmin;
	min-height: 10vmin;
	max-height: 38vmin;
	padding-top: 1vmin;
	padding-right: 0.5vmin;
	padding-bottom: 3vmin;
	padding-left: 0.5vmin;
	flex-wrap: wrap;
	gap: 1.5vmin;
	overflow-x: hidden;
	overflow-y: auto;
	align-content: flex-start;
	clip-path: inset(0 0 0 0 round 3vmin 0 0 3vmin);
	/* -webkit-mask-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 94%, transparent 100%);
	mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 94%, transparent 100%),
		linear-gradient(90deg, transparent 0%, transparent 94%, black 95%); */
}
#nonCharacterButtonsPanel {
	width: 93vmin;
	min-height: 10vmin;
	max-height: 19vmin;
	padding-top: 1vmin;
	padding-right: 0.5vmin;
	padding-bottom: 3vmin;
	padding-left: 0.5vmin;
	flex-wrap: wrap;
	gap: 1.5vmin;
	overflow-x: hidden;
	overflow-y: auto;
	align-content: flex-start;
	clip-path: inset(0 0 0 0 round 3vmin 0 0 3vmin);
	/* -webkit-mask-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 90%, transparent 100%);
	mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 90%, transparent 100%),
		linear-gradient(90deg, transparent 0%, transparent 94%, black 95%); */
}

.spacer {
	width: 2vmin;
	height: 2vmin;
}

.separator {
	height: 0.7vmin;
	background: white;
	margin-left: -3.5vmin;
	margin-right: -3.5vmin;
}

.disabledFromCode {
	pointer-events: none !important;
	opacity: 0.3 !important;
}

.disabledFade {
	opacity: 0.1;
	pointer-events: none;
	filter: grayscale(1);
}

.writerImage {
	background-image: url('Images/IconImages/Icon_Writer_256.png');
}
.doctorImage {
	background-image: url('Images/IconImages/Icon_Doctor_256.png');
}
.characterAvatarImage {
	background-size: 100%;
	width: 35vmin;
	height: 35vmin;
	border: 1vmin solid white;
	border-radius: 100vmin;
}

/* Character Selection Icons */
.character1SelectedImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview1_Selected.png');
}
.character1UnselectedImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview1_Unselected.png');
}
.character1CrossImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview1_Cross.png');
}
.character1HighlightedImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview1_Highlighted.png');
}
.character2SelectedImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview2_Selected.png');
}
.character2UnselectedImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview2_Unselected.png');
}
.character2CrossImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview2_Cross.png');
}
.character2HighlightedImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview2_Highlighted.png');
}
.character3SelectedImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview3_Selected.png');
}
.character3UnselectedImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview3_Unselected.png');
}
.character3CrossImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview3_Cross.png');
}
.character3HighlightedImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview3_Highlighted.png');
}
.character4SelectedImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview4_Selected.png');
}
.character4UnselectedImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview4_Unselected.png');
}
.character4CrossImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview4_Cross.png');
}
.character4HighlightedImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview4_Highlighted.png');
}
.nonCharacterWriterSelectedImage {
	background-image: url('Images/IconImages/Icon_NonCharacterWriterPreview_Selected_128.png');
}
.nonCharacterWriterUnselectedImage {
	background-image: url('Images/IconImages/Icon_NonCharacterWriterPreview_Unselected_128.png');
}
.nonCharacterWriterCrossImage {
	background-image: url('Images/IconImages/Icon_NonCharacterWriterPreview_Cross_128.png');
}
.nonCharacterWriterHighlightedImage {
	background-image: url('Images/IconImages/Icon_NonCharacterWriterPreview_Highlighted_128.png');
}
.characterPendingImage {
	background-image: url('Images/IconImages/Icon_CharacterPreview_Pending_128.png');
}
.tickImage {
	background-image: url('Images/IconImages/Icon_Tick_128.png');
}
.actionsPendingImage {
	background-image: url('Images/IconImages/Icon_ActionsPending_128.png');
}

/* Character Avatar Icons */
.characterAvatarImage_default {
	background-image: url('Images/IconImages/Icon_Avatar_Default_128.png') !important;
}
.characterAvatarImage_player_clownEthan {
	background-image: url('Images/IconImages/Icon_Avatar_player_clownEthan_128.png') !important;
}
.characterAvatarImage_player_footballerJason {
	background-image: url('Images/IconImages/Icon_Avatar_player_footballerJason_128.png') !important;
}
.characterAvatarImage_player_chefPrisha {
	background-image: url('Images/IconImages/Icon_Avatar_player_chefPrisha_128.png') !important;
}
.characterAvatarImage_player_athleteTamira {
	background-image: url('Images/IconImages/Icon_Avatar_player_athleteTamira_128.png') !important;
}
.characterAvatarImage_cammy {
	background-image: url('Images/IconImages/Icon_Avatar_cammy_128.png') !important;
}
.characterAvatarImage_chris {
	background-image: url('Images/IconImages/Icon_Avatar_chris_128.png') !important;
}
.characterAvatarImage_edBanger {
	background-image: url('Images/IconImages/Icon_Avatar_edBanger_128.png') !important;
}
.characterAvatarImage_grace {
	background-image: url('Images/IconImages/Icon_Avatar_grace_128.png') !important;
}
.characterAvatarImage_oliviaAndAvo {
	background-image: url('Images/IconImages/Icon_Avatar_oliviaAndAvo_128.png') !important;
}
.characterAvatarImage_sammi {
	background-image: url('Images/IconImages/Icon_Avatar_sammi_128.png') !important;
}

/* Character Avatar Icons - Cornwall Schools */
.characterAvatarImage_Beth {
	background-image: url('Images/IconImages/Icon_Avatar_Beth_128.png') !important;
}
.characterAvatarImage_Macey {
	background-image: url('Images/IconImages/Icon_Avatar_Macey_128.png') !important;
}
.characterAvatarImage_Evan {
	background-image: url('Images/IconImages/Icon_Avatar_Evan_128.png') !important;
}
.characterAvatarImage_Eadam {
	background-image: url('Images/IconImages/Icon_Avatar_Eadam_128.png') !important;
}
.characterAvatarImage_Gabe {
	background-image: url('Images/IconImages/Icon_Avatar_Gabe_128.png') !important;
}
.characterAvatarImage_Ronaldo {
	background-image: url('Images/IconImages/Icon_Avatar_Ronaldo_128.png') !important;
}
.characterAvatarImage_EthantheEgg {
	background-image: url('Images/IconImages/Icon_Avatar_Ethan the Egg_128.png') !important;
}
.characterAvatarImage_Hanz {
	background-image: url('Images/IconImages/Icon_Avatar_Hanz_128.png') !important;
}
.characterAvatarImage_Vera {
	background-image: url('Images/IconImages/Icon_Avatar_Vera_128.png') !important;
}
.characterAvatarImage_Mama {
	background-image: url('Images/IconImages/Icon_Avatar_Mama_128.png') !important;
}
.characterAvatarImage_Ashley {
	background-image: url('Images/IconImages/Icon_Avatar_Ashley_128.png') !important;
}

.actionButtonImage {
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: 100%;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

/* TODO: @JA please fix RK kludge here. */
.actionButtonCharLabel {
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/*background-size: 100%;*/

	/*background-color: rgba(255, 255, 255, 0.95);*/
	/*backdrop-filter: blur(3vmin);*/
	color: rgb(0, 47, 70);
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: bold;
	border-radius: 3.5vmin;
	border: none;
	box-shadow: 0 1.2vmin rgba(255, 255, 255, 0.75);
	cursor: pointer;
	transition: 0.03s;

	display: flex; /* Use flexbox layout */
	justify-content: center; /* Center text horizontally */
	align-items: center; /* Center text vertically */
	text-align: center; /* Center text */

	font-size: 4em;
}

.actionButtonCharTaken {
	text-decoration: line-through;
}

.itemPreviewImage {
	position: relative;
	top: 4%;
	right: 4%;
	bottom: 4%;
	left: 4%;
	width: 92%;
	height: 92%;
	background-size: 100%;
}

.animatedIcon {
	position: relative;
	background-position-y: 0;
	background-position-x: 0;
	background-repeat: repeat-x;
	animation-timing-function: steps(1); /* Each keyframe uses 1 step */
	animation-iteration-count: infinite;
	animation-direction: normal;
	animation-play-state: running;
	animation-duration: 2s;
}

.actionsWithHeadingLayout {
	gap: 0 !important;
	margin-bottom: 1.5vmin;
	/* background-color:rgba(0, 18, 32, 0.6); */
	background-image: linear-gradient(180deg, rgba(0, 18, 32, 0.3) 80%, transparent 100%);
	border-radius: 3vmin;
}

/* Action Images */
/* ============= */

.moveImage {
	background-image: url('Images/IconImages/Icon_Move_256.png');
}
.actImage {
	background-image: url('Images/IconImages/Icon_Interact_3x3_768.png'); /* CSS3 anim */
	background-size: 300%; /* for 3x3 images */
	animation-duration: 4s;
	animation-name: interactButtonAnimation;
}
.waitImage {
	background-image: url('Images/IconImages/Icon_Wait_4x1_256.png');
	background-size: 400%; /* for 4 images */
	animation-name: spritesheetAnimation4x1;
}
.throwImage {
	background-image: url('Images/IconImages/Icon_Throw_3x1_256.png');
	background-size: 300%; /* for 3 images */
	animation-duration: 1s;
	animation-name: spritesheetAnimation3x1;
}
.lockedImage {
	background-image: url('Images/IconImages/Icon_Padlock_256.png') !important;
	background-size: 100% !important;
	animation-name: none !important;
}

/* Directional Action Images */
/* ========================= */

.moveNorthImage {
	background-image: url('Images/IconImages/Icon_MoveNorth_256.png') !important;
}
.moveEastImage {
	background-image: url('Images/IconImages/Icon_MoveEast_256.png') !important;
}
.moveSouthImage {
	background-image: url('Images/IconImages/Icon_MoveSouth_256.png') !important;
}
.moveWestImage {
	background-image: url('Images/IconImages/Icon_MoveWest_256.png') !important;
}

.actNorthImage {
	background-image: url('Images/IconImages/Icon_InteractNorth_256.png') !important;
}
.actEastImage {
	background-image: url('Images/IconImages/Icon_InteractEast_256.png') !important;
}
.actSouthImage {
	background-image: url('Images/IconImages/Icon_InteractSouth_256.png') !important;
}
.actWestImage {
	background-image: url('Images/IconImages/Icon_InteractWest_256.png') !important;
}

.throwNorthImage {
	background-image: url('Images/IconImages/Icon_ThrowNorth_256.png') !important;
}
.throwEastImage {
	background-image: url('Images/IconImages/Icon_ThrowEast_256.png') !important;
}
.throwSouthImage {
	background-image: url('Images/IconImages/Icon_ThrowSouth_256.png') !important;
}
.throwWestImage {
	background-image: url('Images/IconImages/Icon_ThrowWest_256.png') !important;
}

/* Use spreadsheet to generate "MultiTurn phone controller animation calculations". */
/* Keyframes for 3x3 interact spritesheet */
@keyframes interactButtonAnimation {
	0% {
		background-position: 0% 0%;
	}
	11.11% {
		background-position: 50% 0%;
	}
	22.22% {
		background-position: 100% 0%;
	}
	33.33% {
		background-position: 0% 50%;
	}
	44.44% {
		background-position: 50% 50%;
	}
	55.55% {
		background-position: 100% 50%;
	}
	66.66% {
		background-position: 0% 100%;
	}
	77.77% {
		background-position: 50% 100%;
	}
	88.88% {
		background-position: 100% 100%;
	}
}

/* Keyframes for 2x1 spritesheet */
@keyframes spritesheetAnimation2x1 {
	0% {
		background-position: 0% 0;
	}
	50% {
		background-position: 100% 0;
	}
}

/* Keyframes for 3x1 spritesheet */
@keyframes spritesheetAnimation3x1 {
	0% {
		background-position: 0% 0;
	}
	33.33% {
		background-position: 50% 0;
	}
	66.66% {
		background-position: 100% 0;
	}
}

/* Keyframes for 4x1 spritesheet */
@keyframes spritesheetAnimation4x1 {
	0.0% {
		background-position: 0% 0;
	}
	25.0% {
		background-position: 33.33% 0;
	}
	50.0% {
		background-position: 66.67% 0;
	}
	75.0% {
		background-position: 100% 0;
	}
}

.optionsImage {
	background-image: url('Images/IconImages/Icon_Cog_64.png');
}

.optionsImage:active {
	animation: spinAnimation 0.3s ease-out forwards;
}

@keyframes spinAnimation {
	100% {
		transform: rotate(90deg);
	}
}

.musicOffImage {
	background-image: url('Images/IconImages/Icon_MusicOff_80.png');
}
.musicOnImage {
	background-image: url('Images/IconImages/Icon_MusicOn_80.png');
}

.soundOffImage {
	background-image: url('Images/IconImages/Icon_SoundOff_80.png');
}
.soundOnImage {
	background-image: url('Images/IconImages/Icon_SoundOn_80.png');
}

.timerOffImage {
	background-image: url('Images/IconImages/Icon_SoundOff_80.png');
}
.timerOnImage {
	background-image: url('Images/IconImages/Icon_SoundOn_80.png');
}

.packagePanelImage {
	position: relative;
	top: 0vmin;
	right: 0vmin;
	left: 0vmin;
	width: 45vmin;
	height: 20.6vmin;
	border-top-left-radius: 4.5vmin;
	border-top-right-radius: 4.5vmin;
	background-image: url('Images/Backgrounds/Background1.png');
	background-size: cover;
	pointer-events: none;
}

.packagePanelSeparator {
	position: relative;
	left: -0.5vmin;
	top: 0vmin;
	width: 46vmin;
	height: 0.5vmin;
	background-color: white;
}

.lookAtScreenImage {
	display: block;
	width: 50vmin;
	height: 50vmin;
	background-image: url('Images/IconImages/Icon_LookArrow_256.png');
	background-size: cover;
	animation: arrowBounceAnimation 0.3s ease-out alternate infinite;
}

.lookAtScreenSmallImage {
	display: block;
	width: 22vmin;
	height: 22vmin;
	background-image: url('Images/IconImages/Icon_LookArrow_256.png');
	background-size: cover;
	animation: arrowBounceAnimation 0.3s ease-out alternate infinite;
}

@keyframes arrowBounceAnimation {
	0% {
		transform: translateY(1vmin);
	}
	100% {
		transform: translateY(-2vmin);
	}
}

#lookAtScreenPanel {
	padding-top: 6vmin;
	padding-right: 6vmin;
	padding-bottom: 4vmin;
	padding-left: 6vmin;
}

#bottomRightButtons {
	position: fixed;
	bottom: 3.5vmin;
	right: 2.5vmin;
	transform-origin: bottom right;
}

#characterSelectLayout {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;
	width: 54vmin;
	height: 55.5vmin;
	margin-bottom: 0.5vmin;
	row-gap: 2.8vmin;
	column-gap: 2.2vmin;
}

#readyButton {
	position: relative;
	margin-top: -12vmin;
	padding-left: 10vmin !important;
	padding-right: 10vmin !important;
	padding-top: 2vmin !important;
	padding-bottom: 2vmin !important;
	z-index: 1 !important;
}
.readyButtonActionInput {
	margin-bottom: 0 !important;
}
.readyButtonItemVote {
	margin-top: 1vmin !important;
	margin-bottom: 0 !important;
}
.readyButtonWriterTextInput {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.readyButtonPressed {
	pointer-events: none;
	opacity: 0.5;
	box-shadow: 0 1.2vmin rgba(255, 255, 255, 0.8) !important;
	color: rgba(0, 47, 70, 0.5) !important;
}

#unreadyButton {
	position: relative;
	margin-top: -13vmin;
	padding: 0;
	width: 13vmin;
	height: 13vmin;
	margin-left: 57vmin;
	z-index: 1 !important;
}

.optionsButton {
	width: 14vmin;
	height: 14vmin;
}

.view {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0%;
}

.fullScreenContent {
	position: fixed;
	left: 0px;
	right: 0px;
	bottom: 0px;
	top: 0px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.belowPlayerTabsContent {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 11%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.belowHeaderContent {
	display: flex;
	position: fixed;
	left: 0px;
	right: 0px;
	bottom: 0%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.reloadIfHung {
	/* Hidden at start */
	display: none;
}

.enterBounceAnimated {
	animation: enterBounceAnimation 0.7s ease-in-out;
	animation-fill-mode: backwards;
}

.animDelay1 {
	animation-delay: 0.1s;
}

.animDelay2 {
	animation-delay: 0.2s;
}

@keyframes enterBounceAnimation {
	0% {
		transform: scale(0);
	}
	30% {
		transform: scale(1.15);
	}
	60% {
		transform: scale(0.97);
	}
	100% {
		transform: scale(1);
	}
}

.blurPanel {
	background-color: rgba(0, 47, 70, 0.7);
	backdrop-filter: blur(3vmin);
	border-radius: 5vmin;
	border: 0.7vmin solid white;
	padding-top: 2vmin;
	padding-right: 2vmin;
	padding-bottom: 3.5vmin;
	padding-left: 2vmin;
}

.actionHeadingPanel {
	display: block;
	position: relative;
	text-align: center;
	border-top-left-radius: 3.5vmin;
	border-top-right-radius: 3.5vmin;
	padding-top: 0.8vmin;
	padding-bottom: 0.8vmin;
	width: 97.5vmin;
	z-index: 1;
}

.actionButtonPanel {
	gap: 1vmin !important;
	padding: 1vmin;
	border-radius: 3vmin;
	justify-content: safe center !important; /* Fixes scrolling when items overflow */
	min-width: 0;
	max-width: 97vmin;
	overflow: auto;

	/* -ms-overflow-style: none; for Internet Explorer, Edge */
	/* scrollbar-width: none; for Firefox */
}
.actionButtonPanel::-webkit-scrollbar {
	/* display: none; */
}

.actionPanelStem {
	display: none;
	position: absolute;
	background-size: 100%;
	width: 8vmin;
	height: 8vmin;
	left: 8.5vmin;
	bottom: 3vmin;
	pointer-events: none;
}

/* .tutorialText {
	display: none;
} */

#directionButtonsLayout {
	transition: 0.1s;
}

#playerPreviewGrid {
	/* background-color: rgba(0, 47, 70, 0.7); */
	position: relative;
	background-image: url(Images/IconImages/PlayerGrid_512.png);
	background-size: 100%;
	background-repeat: no-repeat;
	width: 98vmin;
	height: 54vmin;
	margin-top: 1vmin;
}

#characterPreview {
	position: absolute;
	left: 33vmin;
	bottom: 25.5vmin;
	pointer-events: none;
	background-size: 100%;
	background-repeat: no-repeat;
	width: 32vmin;
	height: 32vmin;
}

.characterPreviewImage1 {
	background-image: url(Images/IconImages/PlayerPreview1_400.png);
}
.characterPreviewImage2 {
	background-image: url(Images/IconImages/PlayerPreview2_400.png);
}
.characterPreviewImage3 {
	background-image: url(Images/IconImages/PlayerPreview3_400.png);
}
.characterPreviewImage4 {
	background-image: url(Images/IconImages/PlayerPreview4_400.png);
}

.directionalButton {
	position: absolute;
	background-color: white;
	border-radius: 3.5vmin;
	border: none;
	box-shadow: 1.2vmin 1.2vmin rgba(255, 255, 255, 0.65);
	cursor: pointer;
	transition: 0.03s;
	transform: rotate(25deg) skew(-39deg);
	width: 21vmin;
	height: 15.3vmin;
}

/* Adjustments to position move images more nicely within buttons: */
.directionalButton .moveNorthImage {
	bottom: -4.5vmin;
	left: -4.5vmin;
}
.directionalButton .moveEastImage {
	bottom: -3.5vmin;
	left: -4.5vmin;
}
.directionalButton .moveSouthImage {
	bottom: -3.5vmin;
	left: -4.5vmin;
}
.directionalButton .moveWestImage {
	bottom: -4.5vmin;
	left: -4.5vmin;
}

.directionalButton:hover {
	box-shadow: 1.2vmin 1.2vmin rgba(255, 255, 255, 0.5);
}
.directionalButton:hover .directionalButtonImage {
	opacity: 0.9;
	transform: skew(39deg) rotate(-25deg) translateY(-1vmin);
}

.directionalButton:active {
	background-color: rgba(248, 199, 137, 0.8);
	box-shadow: 0 0 rgba(0, 0, 0, 0);
	transform: translateY(1.5vmin) rotate(26deg) skew(-39deg);
}
.directionalButton:active .directionalButtonImage {
	opacity: 0.9;
	transform: skew(39deg) rotate(-25deg);
}
/* Arrows slide when directional buttons are pressed: */
.directionalButton:active #northArrowImage {
	transform: skew(39deg) rotate(-25deg) translate(3.5vmin, -3.5vmin);
}
.directionalButton:active #eastArrowImage {
	transform: skew(39deg) rotate(-25deg) translate(3.5vmin, 1vmin);
}
.directionalButton:active #southArrowImage {
	transform: skew(39deg) rotate(-25deg) translate(-3.5vmin, 1vmin);
}
.directionalButton:active #westArrowImage {
	transform: skew(39deg) rotate(-25deg) translate(-3.5vmin, -3.5vmin);
}

.directionalButtonSelected {
	filter: brightness(1.2);
}

.directionalButtonSelected .directionalButtonImage {
	transform: skew(39deg) rotate(-25deg) scale(1.1) translateY(-1vmin);
}

.directionalButtonImage {
	position: absolute;
	width: 26vmin;
	height: 26vmin;
	left: -9.8vmin;
	bottom: 0.5vmin;
	background-size: 100%;
	transition: 0.04s;
	transform: skew(39deg) rotate(-25deg);
	pointer-events: none;
}

#northDirectionalButton {
	right: 19vmin;
	bottom: 34vmin;
}
#eastDirectionalButton {
	right: 19vmin;
	bottom: 16.5vmin;
}
#southDirectionalButton {
	left: 19vmin;
	bottom: 16.5vmin;
}
#westDirectionalButton {
	left: 19vmin;
	bottom: 34vmin;
}

.pressEffectButton {
	background-color: rgba(255, 255, 255, 0.95);
	backdrop-filter: blur(3vmin);
	color: rgb(0, 47, 70);
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: bold;
	border-radius: 3.5vmin;
	border: none;
	box-shadow: 0 1.2vmin rgba(255, 255, 255, 0.75);
	cursor: pointer;
	transition: 0.03s;
}

.itemPreview {
	background-color: rgba(255, 255, 255, 1);
	border-radius: 3.5vmin;
	pointer-events: none;
}
.itemPreview.selectedItem {
	background-color: rgba(255, 255, 255, 1);
	border-radius: 3.5vmin;
	box-shadow: 0 0.7vmin rgb(0, 134, 94, 0.4), 0 1.5vmin rgb(0, 194, 136);
	pointer-events: none;
}

.unselectedButton {
	pointer-events: none;
	opacity: 0.25;
}

.selectedScale {
	transform: scale(1.03, 1.05);
}

.buttonOutline {
	border: 0.7vmin solid transparent;
}

.selectedButton {
	background-color: rgba(255, 255, 255, 1);
	border: 0.7vmin solid #0ceaa5;
	box-shadow: 0 1.5vmin #0ceaa5;
}

.tabButton {
	width: 20vmin;
	position: relative;
	height: 15vmin;
	background-color: rgba(255, 255, 255, 0.9);
	color: rgb(0, 47, 70);
	border-bottom-left-radius: 3vmin;
	border-bottom-right-radius: 3vmin;
	box-shadow: 0 1vmin rgba(255, 255, 255, 0.75);
	cursor: pointer;
	transition: 0.03s;
	pointer-events: auto;
}

.playerTab {
	margin-right: 1vmin;
}

.selectedTab {
	background-color: rgba(255, 255, 255, 0.98);
	font-weight: bold;
	box-shadow: 0 1.2vmin rgba(216, 107, 1, 0.9);
	height: 15.8vmin;
}

.secondaryButton {
	background-color: rgba(0, 18, 32, 0.8);
	width: 100%;
	padding-top: 1.2vmin;
	padding-bottom: 1.3vmin;
	backdrop-filter: blur(3vmin);
	color: rgb(255, 255, 255);
	font-weight: bold;
	font-family: 'Atkinson Hyperlegible', sans-serif;
	border-radius: 3vmin;
	border: none;
	cursor: pointer;
	transition: 0.03s;
}

.largeButtonText {
	font-size: 7vmin;
	margin: 0px;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.tabButtonText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	font-size: 5vmin;
	margin-top: 1.7vmin;
	margin-left: 0.5vmin;
	margin-right: 0.5vmin;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.characterSelectButtonText {
	position: absolute;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	font-size: 5.5vmin;
	left: 15vmin;
	margin-top: 3.7vmin;
	right: 2vmin;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}
.characterSelectButtonText.nonCharacterButtonText {
	font-size: 5.5vmin;
	margin-top: 2.8vmin;
	right: 9vmin;
}

.smallButtonText {
	font-size: 4.5vmin;
	margin: 0px;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.nameHeaderText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	top: 0vmin;
	left: 0vmin;
	right: 0vmin;
	text-align: center;
	position: absolute;
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: bold;
	font-size: 8vmin;
	color: white;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.pressEffectButton:hover {
	box-shadow: 0 1.2vmin rgba(255, 255, 255, 0.55);
}

.selectedButton:hover {
	box-shadow: 0 1.5vmin rgb(12, 234, 165, 0.55);
}

.pressEffectButton:active {
	box-shadow: 0 0 rgba(0, 0, 0, 0);
	transform: translateY(1.5vmin);
}

.bootButton {
	margin-bottom: 2.2vmin !important;
	border-radius: 5.5vmin;
}

.bootButton:hover {
	box-shadow: 0 1.2vmin rgba(255, 121, 121, 0.7);
}

.bootButton:active {
	box-shadow: 0 0 rgba(0, 0, 0, 0);
	transform: translateY(1.5vmin);
}

.tabButton:hover {
	box-shadow: 0 1vmin rgba(255, 255, 255, 0.55);
}

.tabButton:active {
	box-shadow: 0 0 rgba(0, 0, 0, 0);
	transform: translateY(1vmin);
}

.selectedTab:hover {
	box-shadow: 0 1.2vmin rgba(250, 199, 139, 0.8);
}

.selectedTab:active {
	box-shadow: 0 0 rgba(0, 0, 0, 0);
	transform: translateY(1.2vmin);
}

.secondaryButton:hover {
	background-color: rgba(0, 18, 32, 1);
}

.secondaryButton:active {
	background-color: rgba(0, 18, 32, 1);
}

.pressEffectButtonSmall:hover {
	box-shadow: 0 0.8vmin rgba(255, 255, 255, 0.55);
}

.pressEffectButtonSmall:active {
	box-shadow: 0 0 rgba(0, 0, 0, 0);
	transform: translateY(0.8vmin);
}

.mainButtonSpacing {
	margin-top: 1.5vmin;
	margin-bottom: 1.5vmin;
	padding: 2.5vmin;
	padding-left: 5vmin;
	padding-right: 5vmin;
}

.smallButtonSpacing {
	margin-top: 1vmin;
	margin-bottom: 1vmin;
	padding: 1.5vmin;
	padding-left: 4vmin;
	padding-right: 4vmin;
}

.optionsButtonSpacing {
	padding: 0.5vmin;
}

.actionInfoContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.titleText {
	color: white;
	text-shadow: black 0px 0px 2px;
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: bold;
	margin-top: 1.8vmin;
	font-size: 7vmin;
	margin-bottom: 0.8vmin;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.infoText {
	color: white;
	text-shadow: black 0px 0px 2px;
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: bold;
	font-size: 4vmin;
	margin: 0px;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.actionButtonInnerText {
	position: absolute;
	left: 1vmin;
	right: 1vmin;
	bottom: 1vmin;
	color: white;
	vertical-align: text-bottom;
	/* text-shadow: black 0px 0px 2px; */
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: bold;
	font-size: 4vmin;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.actionLabelText {
	position: absolute;
	width: 22vmin;
	margin-top: -0.1vmin;
	color: white;
	text-shadow: black 0px 0px 2px;
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: bold;
	font-size: 4vmin;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.actionNumberText {
	display: block;
	position: absolute;
	width: 5.8vmin;
	height: 6vmin;
	left: 0;
	top: 0;
	color: white;
	text-shadow: black 0px 0px 2px;
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: bold;
	font-size: 5vmin;
	margin: 0px;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.tutorialText {
	padding-top: 0.2vmin;
	margin-bottom: -1vmin;
}

.writerCharacterNameText {
	width: 90vmin;
	margin-top: -9vmin;
	border-bottom-left-radius: 3vmin;
	border-bottom-right-radius: 3vmin;
	padding-left: 4vmin;
	padding-right: 4vmin;
	font-size: 6vmin;
	z-index: 2;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.playerNameListElement {
	background: linear-gradient(rgba(11, 23, 35, 0.5), rgba(11, 23, 35));
	width: 88vmin;
	height: 10vmin;
}

.playerNameText {
	color: white;
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: bold;
	font-size: 5vmin;
	width: 70vmin;
	margin: 0;
	margin-left: 1vmin;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.smallText {
	color: white;
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: normal;
	font-size: 4vmin;
	margin: 0px;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.packagePanelText {
	padding: 1vmin;
	height: 12vmin;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.joinFormField {
	color: rgb(0, 47, 70);
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: bold;
	text-align: center;
	font-size: 7vmin;
	padding: 3vmin;
	border: none;
	border-radius: 2vmin;
	width: 80%;
	height: 5vmin;
}

.writerInputField {
	color: rgb(0, 47, 70);
	font-family: 'Atkinson Hyperlegible', sans-serif;
	font-weight: bold;
	font-size: 7vmin;
	padding: 3vmin;
	border-radius: 2vmin;
	width: 90%;
	height: 34vmin;
	line-height: 8vmin;
	margin-top: 2vmin;
	margin-bottom: 2vmin;
}

.socialButton {
	width: 11vmin;
	height: 11vmin;
	padding: 1.5vmin;
	transition: 0.03s;
}

.socialButton:hover {
	transform: scale(1.05);
	opacity: 0.9;
}

.socialButton:active {
	transform: scale(0.98);
}

.verticalCentreLayout {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	flex-direction: column;
	text-align: center;
}

.horizontalPanelLayout {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	text-align: center;
	gap: 2.5vmin;
}

.adaptivePanelLayout {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2.5vmin;
}

.adaptiveInputPhaseLayout {
	display: flex;
	justify-content: center;
	align-items: center;
}

.verticalPanelLayout {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 2.5vmin;
}

.actionButtonLayout {
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 3vmin;
}

.actionButtonSquareLayout {
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 3vmin;
	width: 80vmin;
	height: 80vmin;
}

.actionButton {
	position: relative;
	background-color: rgba(0, 18, 32, 0.8);
	text-align: center;
	width: 22vmin;
	height: 22vmin;
	transition: 0.03s;
	cursor: pointer;
	border-radius: 1.5vmin;
	border: 0.4vmin solid black;
	overflow: hidden;
	flex-shrink: 0;
}
.actionButton:hover {
	filter: brightness(1.1);
}
.actionButton:active {
	opacity: 0.8;
	transform: scale(0.9);
}

.actionButtonRound {
	position: relative;
	background-color: rgba(0, 18, 32, 0.8);
	text-align: center;
	width: 22vmin;
	height: 22vmin;
	transition: 0.03s;
	cursor: pointer;
	border-radius: 50%;
	border: 0.4vmin solid black;
}

.actionButtonInner {
	position: absolute;
	background-color: rgba(255, 255, 255, 0.3);
	top: 1vmin;
	right: 1vmin;
	bottom: 1vmin;
	left: 1vmin;
	border-radius: 1vmin;
	border: 0.4vmin solid black;
}

.actionButtonRound .actionButtonInner {
	border-radius: 50%;
}

.actionButtonInnerTextLayout {
	position: absolute;
	align-content: center;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	display: flex;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.actionButtonWithLabel {
	height: 25.8vmin;
}
.actionButtonWithLabel .actionButtonInner {
	top: 4.8vmin;
}

.actionButtonPreviewMove {
	background-color: #019748;
}
.actionButtonPreviewMove .actionButtonInner {
	background-color: #bdffdc;
}
.actionButtonPreviewInteract {
	background-color: #c86400;
}
.actionButtonPreviewInteract .actionButtonInner {
	background-color: #ffd9b3;
}
.actionButtonPreviewWait {
	background-color: #2397e4;
}
.actionButtonPreviewWait .actionButtonInner {
	background-color: #b9e3ff;
}
.actionButtonPreviewThrow {
	background-color: #e8d734;
}
.actionButtonPreviewThrow .actionButtonInner {
	background-color: #fffdc0;
}

.actionButtonSelected {
	border: 0.4vmin solid white;
}
.actionButtonSelected .actionButtonInner {
	border-color: white;
}

.actionButtonItemPosition {
	background-color: rgb(0, 134, 94);
}
.actionButtonItemPosition .actionButtonInner {
	background-color: rgb(0, 194, 136);
}

.disabledButton {
	opacity: 0.3;
	pointer-events: none;
}

.configButton {
	width: 25vmin;
	height: 25vmin;
}

.characterSelectButton {
	position: relative;
	width: 46%;
	height: 14vmin;
	margin-top: 1vmin;
	overflow: hidden;
}
.characterSelectButton.nonCharacterButton {
	width: 95%;
	height: 12vmin;
}

.characterSelectButtonImage {
	position: absolute;
	bottom: -2vmin;
	left: -3vmin;
	width: 18vmin;
	height: 18vmin;
	background-size: 100%;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}
.characterSelectButtonImage.nonCharacterButtonImage {
	bottom: -2vmin;
	left: -2.5vmin;
	width: 16vmin;
	height: 16vmin;
}

.writerCharacterButton {
	display: grid;
	scroll-snap-align: center;
}

.placeholderButton {
	cursor: auto;
	opacity: 0;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.actionNumberImage {
	display: block;
	position: relative;
	width: 6vmin;
	height: 6vmin;
	left: 0;
	top: 0;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.actionNumberImageSmall {
	display: block;
	position: relative;
	width: 5vmin;
	height: 5vmin;
	left: -1vmin;
	top: -14.6vmin;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.packagePanel {
	width: 45vmin;
	height: 36vmin;
	padding: 0px;
}

.packagePanel.sidePackagePanel {
	transform: scale(0.9);
	opacity: 0.75;
}

.arrowButton {
	background-image: url(Images/IconImages/Icon_Arrow1_128.png);
	background-size: cover;
	background-repeat: no-repeat;
	width: 16vmin;
	height: 16vmin;
	padding: 5vmin;
	background-origin: content-box;
	cursor: pointer;
	transition: 0.03s;
}

.arrowButton.rightArrow {
	transform: scaleX(-1);
}

.arrowButton:hover {
	opacity: 0.75;
}

.arrowButton:active {
	transform: scale(0.8);
}

.arrowButton.rightArrow:active {
	transform: scale(-0.8, 0.8);
}

.characterScrollSection {
	background-color: rgba(0, 18, 32, 0.4);
	margin-top: 0.8vmin;
	padding-top: 1.5vmin;
	padding-bottom: 9vmin;
	width: 96vmin;
	display: grid;
	grid-auto-flow: column;
	gap: 2vmin;
	overflow-x: auto;
	overflow-y: hidden;
	scroll-snap-type: x mandatory;
	scroll-snap-stop: always;
	scroll-behavior: smooth;

	-webkit-mask-image: -webkit-linear-gradient(
		left,
		transparent 0%,
		rgba(0, 0, 0, 1) 10%,
		rgba(0, 0, 0, 1) 90%,
		transparent 100%
	);
	mask-image: linear-gradient(90deg, transparent 0%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, transparent 100%);

	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
}
.characterScrollSection::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
}

.overlay {
	position: fixed;
	top: 0%;
	right: 0%;
	bottom: 0%;
	left: 0%;
}

.defaultBlur {
	background-color: rgba(0, 27, 41, 0.4);
	backdrop-filter: blur(4vmin);
}

.darkBlur {
	background-color: rgba(0, 18, 32, 0.8);
	backdrop-filter: blur(1vmin);
}

.buttonPromptAnimated {
	animation: buttonPromptAnimation 0.2s forwards ease-in;
}

@keyframes buttonPromptAnimation {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	50% {
		transform: scale(1.1);
		opacity: 0.9;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

.fadeInAnimated {
	animation: fadeAnimation 0.2s;
	animation-delay: 0.15s;
	animation-fill-mode: backwards;
}

.fadeInQuickAnimated {
	animation: fadeAnimation 0.1s;
	animation-delay: 0s;
	animation-fill-mode: backwards;
}

@keyframes fadeAnimation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.applyScaleMult {
	transform: scale(var(--scaleMult));
}
.applyScaleMultY {
	transform: scaleY(var(--scaleMult));
}
.applyScaleMultX {
	transform: scaleX(var(--scaleMult));
}

.playerImage {
	width: 16vmin;
	height: 16vmin;
	background-size: cover;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

#menuImage {
	position: relative;
	top: 25%;
	right: 16%;
	bottom: 7%;
	left: 16%;
	width: 68%;
	height: 68%;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: bottom;
	background-image: url('Images/IconImages/Icon_Menu_128.png');
}

.tabPlayerImage {
	position: absolute;
	left: 0.8vmin;
	bottom: 0.4vmin;
	width: 10vmin;
	height: 10vmin;
	background-size: cover;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

.tabReadyImage {
	position: absolute;
	right: 0.3vmin;
	bottom: 0.6vmin;
	width: 9vmin;
	height: 9vmin;
	background-size: cover;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
}

/* -- Media Queries -- */
/* ------------------- */

/* Pointer, i.e. NOT touch screen device */
@media (any-pointer: fine), (pointer: fine) {
	/* Custom Scrollbar */
	::-webkit-scrollbar {
		width: 4vmin;
		height: 4vmin;
	}
	/* Track */
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 1.6vmin;
	}
	/* Handle */
	::-webkit-scrollbar-thumb {
		background: rgba(0, 23, 35, 0.4);
		border-radius: 1.5vmin;
		width: 3vmin;
		height: 3vmin;
		border: 0.5vmin solid white;
	}
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: rgba(0, 23, 35, 0.5);
	}
	/* Handle on hover */
	::-webkit-scrollbar-thumb:active {
		background: rgba(0, 23, 35, 0.6);
	}
}

/* Portrait screens */
@media (orientation: portrait) {
	html {
		--scaleMult: 1;
	}

	.spacerInPortrait {
		width: 2vmin;
		height: 2vmin;
	}

	.multiturnHeader {
		height: 15%;
	}

	.belowHeaderContent {
		top: 15%;
	}

	.adaptivePanelLayout {
		flex-direction: column;
	}

	.adaptiveInputPhaseLayout {
		flex-direction: column;
		gap: 0;
	}

	.actionInfoContainer {
		width: 75vmin;
	}

	.actionButtonLayout {
		width: 80vmin;
		height: 80vmin;
	}

	.hideInPortrait {
		display: none;
	}
}

/* Scale things down for increasingly near-square portrait aspect ratios */
@media (min-aspect-ratio: 12/18) {
	html {
		--scaleMult: 0.86;
	}
}
@media (min-aspect-ratio: 14/18) {
	html {
		--scaleMult: 0.7;
	}
}
@media (min-aspect-ratio: 16/18) {
	html {
		--scaleMult: 0.62;
	}
}

/* Landscape screens */
@media (orientation: landscape) {
	html {
		--scaleMult: 0.5;
	}

	#gameInputMainLayout {
		align-items: flex-start;
	}

	#actionSelectButtonsPanel {
		z-index: 1;
	}

	#directionHeading {
		/* Full width: 98 */
		width: 81vmin;
		margin-left: 8.5vmin;
	}

	#directionButtonsLayout {
		width: 85vmin;
		transform: translate(2vmin, -24.5vmin);
	}

	#playerPreviewGrid {
		margin-left: 0vmin;
	}

	.spacerInLandscape {
		width: 2vmin;
		height: 2vmin;
	}

	.readyButtonActionInput {
		margin-top: -25vmin !important;
		margin-left: 66vmin !important;
	}
	.unreadyButtonActionInput {
		margin-top: -25vmin !important;
		margin-left: 110vmin !important;
	}

	.readyButtonWriterTextInput {
		margin-top: -2vmin !important;
	}

	.multiturnHeader {
		height: 20%;
	}

	.belowHeaderContent {
		top: 20%;
	}

	.writerTextInputContent {
		top: 5% !important;
	}

	.adaptivePanelLayout {
		flex-direction: row;
	}

	.adaptiveInputPhaseLayout {
		flex-direction: row;
		align-items: flex-start;
	}

	.actionInfoContainer {
		width: 80vmin;
		height: 16vmin;
	}

	.actionButtonLayout {
		width: 165vmin;
		height: 40vmin;
	}

	.actionButtonPanel {
		margin-bottom: 0;
	}

	.writerInputField {
		height: 8vmin !important;
	}

	.hideInLandscape {
		display: none;
	}
}

/* Scale things back up for increasingly wide landscape aspect ratios */
@media (min-aspect-ratio: 18/16) {
	html {
		--scaleMult: 0.55;
	}
}
@media (min-aspect-ratio: 18/13) {
	html {
		--scaleMult: 0.72;
	}
}
@media (min-aspect-ratio: 18/11) {
	html {
		--scaleMult: 0.85;
	}
}
