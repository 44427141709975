html {
  --scaleMult: 1;
  backface-visibility: hidden;
}

body {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-input-placeholder {
  color: #002f4699;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-style: italic;
  font-weight: normal;
}

::placeholder {
  color: #002f4699;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-style: italic;
  font-weight: normal;
}

:-ms-input-placeholder {
  color: #002f4699;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-style: italic;
  font-weight: normal;
}

#backgroundImage {
  pointer-events: none;
  background-image: url("Background1.87590f87.png");
  background-position: center;
  background-size: cover;
  position: fixed;
  inset: 0;
}

#background {
  background-color: #005771;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#Default, #Connecting, #Reconnecting, #Lobby, #PlayerTypeSelect, #WaitOrAddPlayers, #WaitOrChoosePackage, #CharacterSelect, #GameInputPhase, #ItemPlacementVote, #WriterTextInput, #LookAtScreen {
  display: none;
}

.multiturnHeader {
  -webkit-backdrop-filter: blur(3vmin);
  backdrop-filter: blur(3vmin);
  border-bottom: calc(.7vmin * var(--scaleMult)) white solid;
  background-color: #002f46b3;
  width: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#logo {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  height: 80%;
}

#splashLogo {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: 70vmin;
}

.heartrateLoader {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-mask-image: -webkit-linear-gradient(left, #0000 0%, #000 10% 90%, #0000 100%);
  width: 40vmin;
  height: 40vmin;
  mask-image: linear-gradient(90deg, #0000 0%, #000 10% 90%, #0000 100%);
}

#socialLinks {
  transform-origin: 0 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 1.2vmin;
  left: 2vmin;
}

#playerTabsAndMenuLayout {
  transform-origin: top;
  z-index: 1;
  flex-direction: column;
  align-items: stretch;
  height: 12.5vmin;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
}

#playerTabsLayout {
  pointer-events: none;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0;
  height: 20vmin;
  margin-top: -17.5vmin;
  padding-left: 2vmin;
  display: flex;
  right: 5vmin;
  overflow-x: hidden;
}

#menuButtonLayout {
  flex-direction: row;
  justify-content: flex-end;
  gap: 1vmin;
  height: 20vmin;
  margin-top: -5vmin;
  padding-left: 2vmin;
  padding-right: 2vmin;
  display: flex;
  overflow-x: hidden;
}

.anchorBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.anchorTop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#joinPanel {
  -webkit-backdrop-filter: blur(3vmin);
  backdrop-filter: blur(3vmin);
  background-color: #002f46b3;
  border: .7vmin solid #fff;
  border-radius: 5vmin;
  width: 75vmin;
  margin-bottom: 1vmin;
  padding-bottom: 3vmin;
}

#directionHeading {
  padding-bottom: 28vmin;
}

#playerNameTop {
  text-overflow: ellipsis;
  white-space: nowrap;
  visibility: visible;
  flex-grow: 1;
  flex-basis: 0;
  height: 7vmin;
  padding-top: 7.5vmin;
  padding-right: 14vmin;
  font-size: 5vmin;
  overflow: hidden;
}

#playerNameOverflowPanel {
  left: 0;
  right: 0;
  top: calc(12vmin * var(--scaleMult));
  transform-origin: top;
  text-align: center;
  visibility: visible;
  flex-direction: column;
  align-items: stretch;
  height: 7vmin;
  display: flex;
}

#playerNameOverflow {
  padding-top: .9vmin;
  font-size: 4.2vmin;
}

#itemCarouselLayout {
  clip-path: inset(0);
  width: 96vmin;
  height: 45vmin;
  position: relative;
}

#itemPositionButtonsLayout, #itemPositionButtonsPanel {
  width: 96vmin;
}

#characterButtonsPanel {
  clip-path: inset(0 round 3vmin 0 0 3vmin);
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 1.5vmin;
  width: 93vmin;
  min-height: 10vmin;
  max-height: 38vmin;
  padding: 1vmin .5vmin 3vmin;
  overflow-x: hidden;
  overflow-y: auto;
}

#nonCharacterButtonsPanel {
  clip-path: inset(0 round 3vmin 0 0 3vmin);
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 1.5vmin;
  width: 93vmin;
  min-height: 10vmin;
  max-height: 19vmin;
  padding: 1vmin .5vmin 3vmin;
  overflow-x: hidden;
  overflow-y: auto;
}

.spacer {
  width: 2vmin;
  height: 2vmin;
}

.separator {
  background: #fff;
  height: .7vmin;
  margin-left: -3.5vmin;
  margin-right: -3.5vmin;
}

.disabledFromCode {
  pointer-events: none !important;
  opacity: .3 !important;
}

.disabledFade {
  opacity: .1;
  pointer-events: none;
  filter: grayscale();
}

.writerImage {
  background-image: url("Icon_Writer_256.7e76b3fb.png");
}

.doctorImage {
  background-image: url("Icon_Doctor_256.2694d58e.png");
}

.characterAvatarImage {
  background-size: 100%;
  border: 1vmin solid #fff;
  border-radius: 100vmin;
  width: 35vmin;
  height: 35vmin;
}

.character1SelectedImage {
  background-image: url("Icon_CharacterPreview1_Selected.b5b425ad.png");
}

.character1UnselectedImage {
  background-image: url("Icon_CharacterPreview1_Unselected.fa751ba4.png");
}

.character1CrossImage {
  background-image: url("Icon_CharacterPreview1_Cross.0b9fadbd.png");
}

.character1HighlightedImage {
  background-image: url("Icon_CharacterPreview1_Highlighted.6f19ef6a.png");
}

.character2SelectedImage {
  background-image: url("Icon_CharacterPreview2_Selected.331027aa.png");
}

.character2UnselectedImage {
  background-image: url("Icon_CharacterPreview2_Unselected.bb8415be.png");
}

.character2CrossImage {
  background-image: url("Icon_CharacterPreview2_Cross.3d80c7d4.png");
}

.character2HighlightedImage {
  background-image: url("Icon_CharacterPreview2_Highlighted.b4fcd94b.png");
}

.character3SelectedImage {
  background-image: url("Icon_CharacterPreview3_Selected.5b26ef21.png");
}

.character3UnselectedImage {
  background-image: url("Icon_CharacterPreview3_Unselected.eadff3c4.png");
}

.character3CrossImage {
  background-image: url("Icon_CharacterPreview3_Cross.2d420954.png");
}

.character3HighlightedImage {
  background-image: url("Icon_CharacterPreview3_Highlighted.6166b098.png");
}

.character4SelectedImage {
  background-image: url("Icon_CharacterPreview4_Selected.c1b394b8.png");
}

.character4UnselectedImage {
  background-image: url("Icon_CharacterPreview4_Unselected.92a97ac3.png");
}

.character4CrossImage {
  background-image: url("Icon_CharacterPreview4_Cross.ae0a38f6.png");
}

.character4HighlightedImage {
  background-image: url("Icon_CharacterPreview4_Highlighted.4f83e7d4.png");
}

.nonCharacterWriterSelectedImage {
  background-image: url("Icon_NonCharacterWriterPreview_Selected_128.8874f85a.png");
}

.nonCharacterWriterUnselectedImage {
  background-image: url("Icon_NonCharacterWriterPreview_Unselected_128.c51b4459.png");
}

.nonCharacterWriterCrossImage {
  background-image: url("Icon_NonCharacterWriterPreview_Cross_128.96580c6a.png");
}

.nonCharacterWriterHighlightedImage {
  background-image: url("Icon_NonCharacterWriterPreview_Highlighted_128.102a20e3.png");
}

.characterPendingImage {
  background-image: url("Icon_CharacterPreview_Pending_128.135b6754.png");
}

.tickImage {
  background-image: url("Icon_Tick_128.258610f5.png");
}

.actionsPendingImage {
  background-image: url("Icon_ActionsPending_128.828e0717.png");
}

.characterAvatarImage_default {
  background-image: url("Icon_Avatar_Default_128.9ea6f6df.png") !important;
}

.characterAvatarImage_player_clownEthan {
  background-image: url("Icon_Avatar_player_clownEthan_128.e046be16.png") !important;
}

.characterAvatarImage_player_footballerJason {
  background-image: url("Icon_Avatar_player_footballerJason_128.b9534ec8.png") !important;
}

.characterAvatarImage_player_chefPrisha {
  background-image: url("Icon_Avatar_player_chefPrisha_128.45477b14.png") !important;
}

.characterAvatarImage_player_athleteTamira {
  background-image: url("Icon_Avatar_player_athleteTamira_128.6b3027d9.png") !important;
}

.characterAvatarImage_cammy {
  background-image: url("Icon_Avatar_cammy_128.b3faaecd.png") !important;
}

.characterAvatarImage_chris {
  background-image: url("Icon_Avatar_chris_128.7ec6e205.png") !important;
}

.characterAvatarImage_edBanger {
  background-image: url("Icon_Avatar_edBanger_128.da36903f.png") !important;
}

.characterAvatarImage_grace {
  background-image: url("Icon_Avatar_grace_128.3e2d940d.png") !important;
}

.characterAvatarImage_oliviaAndAvo {
  background-image: url("Icon_Avatar_oliviaAndAvo_128.592cc7d1.png") !important;
}

.characterAvatarImage_sammi {
  background-image: url("Icon_Avatar_sammi_128.bb5606d5.png") !important;
}

.characterAvatarImage_Beth {
  background-image: url("Icon_Avatar_Beth_128.f845c68e.png") !important;
}

.characterAvatarImage_Macey {
  background-image: url("Icon_Avatar_Macey_128.cc12537c.png") !important;
}

.characterAvatarImage_Evan {
  background-image: url("Icon_Avatar_Evan_128.bb3f3d09.png") !important;
}

.characterAvatarImage_Eadam {
  background-image: url("Icon_Avatar_Eadam_128.0c52e9e7.png") !important;
}

.characterAvatarImage_Gabe {
  background-image: url("Icon_Avatar_Gabe_128.745bf40e.png") !important;
}

.characterAvatarImage_Ronaldo {
  background-image: url("Icon_Avatar_Ronaldo_128.3a3619a3.png") !important;
}

.characterAvatarImage_EthantheEgg {
  background-image: url("Icon_Avatar_Ethan the Egg_128.b8755424.png") !important;
}

.characterAvatarImage_Hanz {
  background-image: url("Icon_Avatar_Hanz_128.f4cf4fda.png") !important;
}

.characterAvatarImage_Vera {
  background-image: url("Icon_Avatar_Vera_128.6e633af0.png") !important;
}

.characterAvatarImage_Mama {
  background-image: url("Icon_Avatar_Mama_128.850ed4c6.png") !important;
}

.characterAvatarImage_Ashley {
  background-image: url("Icon_Avatar_Ashley_128.fa89d39b.png") !important;
}

.actionButtonImage {
  -webkit-user-select: none;
  user-select: none;
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: relative;
  inset: 0;
}

.actionButtonCharLabel {
  color: #002f46;
  cursor: pointer;
  text-align: center;
  border: none;
  border-radius: 3.5vmin;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-size: 4em;
  font-weight: bold;
  transition: all 30ms;
  display: flex;
  position: relative;
  inset: 0;
  box-shadow: 0 1.2vmin #ffffffbf;
}

.actionButtonCharTaken {
  text-decoration: line-through;
}

.itemPreviewImage {
  background-size: 100%;
  width: 92%;
  height: 92%;
  position: relative;
  inset: 4%;
}

.animatedIcon {
  background-position: 0 0;
  background-repeat: repeat-x;
  animation-duration: 2s;
  animation-timing-function: step-end;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
  position: relative;
}

.actionsWithHeadingLayout {
  background-image: linear-gradient(#0012204d 80%, #0000 100%);
  border-radius: 3vmin;
  margin-bottom: 1.5vmin;
  gap: 0 !important;
}

.moveImage {
  background-image: url("Icon_Move_256.57eebdb1.png");
}

.actImage {
  background-image: url("Icon_Interact_3x3_768.3a669d0c.png");
  background-size: 300%;
  animation-name: interactButtonAnimation;
  animation-duration: 4s;
}

.waitImage {
  background-image: url("Icon_Wait_4x1_256.c225c6a6.png");
  background-size: 400%;
  animation-name: spritesheetAnimation4x1;
}

.throwImage {
  background-image: url("Icon_Throw_3x1_256.48997afa.png");
  background-size: 300%;
  animation-name: spritesheetAnimation3x1;
  animation-duration: 1s;
}

.lockedImage {
  background-image: url("Icon_Padlock_256.e3d9f3f4.png") !important;
  background-size: 100% !important;
  animation-name: none !important;
}

.moveNorthImage {
  background-image: url("Icon_MoveNorth_256.242b6cbe.png") !important;
}

.moveEastImage {
  background-image: url("Icon_MoveEast_256.d4f7ab2f.png") !important;
}

.moveSouthImage {
  background-image: url("Icon_MoveSouth_256.503a16fa.png") !important;
}

.moveWestImage {
  background-image: url("Icon_MoveWest_256.eb33f8ca.png") !important;
}

.actNorthImage {
  background-image: url("Icon_InteractNorth_256.bb5a2795.png") !important;
}

.actEastImage {
  background-image: url("Icon_InteractEast_256.88cc57d0.png") !important;
}

.actSouthImage {
  background-image: url("Icon_InteractSouth_256.f9ea9888.png") !important;
}

.actWestImage {
  background-image: url("Icon_InteractWest_256.b0597507.png") !important;
}

.throwNorthImage {
  background-image: url("Icon_ThrowNorth_256.d13bc3f0.png") !important;
}

.throwEastImage {
  background-image: url("Icon_ThrowEast_256.678d3150.png") !important;
}

.throwSouthImage {
  background-image: url("Icon_ThrowSouth_256.d3b862fc.png") !important;
}

.throwWestImage {
  background-image: url("Icon_ThrowWest_256.92b63f89.png") !important;
}

@keyframes interactButtonAnimation {
  0% {
    background-position: 0 0;
  }

  11.11% {
    background-position: 50% 0;
  }

  22.22% {
    background-position: 100% 0;
  }

  33.33% {
    background-position: 0%;
  }

  44.44% {
    background-position: 50%;
  }

  55.55% {
    background-position: 100%;
  }

  66.66% {
    background-position: 0 100%;
  }

  77.77% {
    background-position: 50% 100%;
  }

  88.88% {
    background-position: 100% 100%;
  }
}

@keyframes spritesheetAnimation2x1 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}

@keyframes spritesheetAnimation3x1 {
  0% {
    background-position: 0 0;
  }

  33.33% {
    background-position: 50% 0;
  }

  66.66% {
    background-position: 100% 0;
  }
}

@keyframes spritesheetAnimation4x1 {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 33.33% 0;
  }

  50% {
    background-position: 66.67% 0;
  }

  75% {
    background-position: 100% 0;
  }
}

.optionsImage {
  background-image: url("Icon_Cog_64.5b063b50.png");
}

.optionsImage:active {
  animation: .3s ease-out forwards spinAnimation;
}

@keyframes spinAnimation {
  100% {
    transform: rotate(90deg);
  }
}

.musicOffImage {
  background-image: url("Icon_MusicOff_80.0ec58410.png");
}

.musicOnImage {
  background-image: url("Icon_MusicOn_80.7d78d4fe.png");
}

.soundOffImage {
  background-image: url("Icon_SoundOff_80.041303b0.png");
}

.soundOnImage {
  background-image: url("Icon_SoundOn_80.5c000b13.png");
}

.timerOffImage {
  background-image: url("Icon_SoundOff_80.041303b0.png");
}

.timerOnImage {
  background-image: url("Icon_SoundOn_80.5c000b13.png");
}

.packagePanelImage {
  pointer-events: none;
  background-image: url("Background1.87590f87.png");
  background-size: cover;
  border-top-left-radius: 4.5vmin;
  border-top-right-radius: 4.5vmin;
  width: 45vmin;
  height: 20.6vmin;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.packagePanelSeparator {
  background-color: #fff;
  width: 46vmin;
  height: .5vmin;
  position: relative;
  top: 0;
  left: -.5vmin;
}

.lookAtScreenImage {
  background-image: url("Icon_LookArrow_256.1ad25642.png");
  background-size: cover;
  width: 50vmin;
  height: 50vmin;
  animation: .3s ease-out infinite alternate arrowBounceAnimation;
  display: block;
}

.lookAtScreenSmallImage {
  background-image: url("Icon_LookArrow_256.1ad25642.png");
  background-size: cover;
  width: 22vmin;
  height: 22vmin;
  animation: .3s ease-out infinite alternate arrowBounceAnimation;
  display: block;
}

@keyframes arrowBounceAnimation {
  0% {
    transform: translateY(1vmin);
  }

  100% {
    transform: translateY(-2vmin);
  }
}

#lookAtScreenPanel {
  padding: 6vmin 6vmin 4vmin;
}

#bottomRightButtons {
  transform-origin: 100% 100%;
  position: fixed;
  bottom: 3.5vmin;
  right: 2.5vmin;
}

#characterSelectLayout {
  flex-flow: wrap;
  place-content: center;
  gap: 2.8vmin 2.2vmin;
  width: 54vmin;
  height: 55.5vmin;
  margin-bottom: .5vmin;
  display: flex;
}

#readyButton {
  margin-top: -12vmin;
  position: relative;
  z-index: 1 !important;
  padding: 2vmin 10vmin !important;
}

.readyButtonActionInput {
  margin-bottom: 0 !important;
}

.readyButtonItemVote {
  margin-top: 1vmin !important;
  margin-bottom: 0 !important;
}

.readyButtonWriterTextInput {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.readyButtonPressed {
  pointer-events: none;
  opacity: .5;
  color: #002f4680 !important;
  box-shadow: 0 1.2vmin #fffc !important;
}

#unreadyButton {
  width: 13vmin;
  height: 13vmin;
  margin-top: -13vmin;
  margin-left: 57vmin;
  padding: 0;
  position: relative;
  z-index: 1 !important;
}

.optionsButton {
  width: 14vmin;
  height: 14vmin;
}

.view {
  position: fixed;
  inset: 0 0 0%;
}

.fullScreenContent {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.belowPlayerTabsContent {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 11% 0 0;
}

.belowHeaderContent {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 0%;
  left: 0;
  right: 0;
}

.reloadIfHung {
  display: none;
}

.enterBounceAnimated {
  animation: .7s ease-in-out backwards enterBounceAnimation;
}

.animDelay1 {
  animation-delay: .1s;
}

.animDelay2 {
  animation-delay: .2s;
}

@keyframes enterBounceAnimation {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1.15);
  }

  60% {
    transform: scale(.97);
  }

  100% {
    transform: scale(1);
  }
}

.blurPanel {
  -webkit-backdrop-filter: blur(3vmin);
  backdrop-filter: blur(3vmin);
  background-color: #002f46b3;
  border: .7vmin solid #fff;
  border-radius: 5vmin;
  padding: 2vmin 2vmin 3.5vmin;
}

.actionHeadingPanel {
  text-align: center;
  z-index: 1;
  border-top-left-radius: 3.5vmin;
  border-top-right-radius: 3.5vmin;
  width: 97.5vmin;
  padding-top: .8vmin;
  padding-bottom: .8vmin;
  display: block;
  position: relative;
}

.actionButtonPanel {
  border-radius: 3vmin;
  min-width: 0;
  max-width: 97vmin;
  padding: 1vmin;
  overflow: auto;
  justify-content: safe center !important;
  gap: 1vmin !important;
}

.actionPanelStem {
  pointer-events: none;
  background-size: 100%;
  width: 8vmin;
  height: 8vmin;
  display: none;
  position: absolute;
  bottom: 3vmin;
  left: 8.5vmin;
}

#directionButtonsLayout {
  transition: all .1s;
}

#playerPreviewGrid {
  background-image: url("PlayerGrid_512.bef839df.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 98vmin;
  height: 54vmin;
  margin-top: 1vmin;
  position: relative;
}

#characterPreview {
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 32vmin;
  height: 32vmin;
  position: absolute;
  bottom: 25.5vmin;
  left: 33vmin;
}

.characterPreviewImage1 {
  background-image: url("PlayerPreview1_400.a0affa7c.png");
}

.characterPreviewImage2 {
  background-image: url("PlayerPreview2_400.fe0adc8e.png");
}

.characterPreviewImage3 {
  background-image: url("PlayerPreview3_400.d5fd183e.png");
}

.characterPreviewImage4 {
  background-image: url("PlayerPreview4_400.e114c7d0.png");
}

.directionalButton {
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 3.5vmin;
  width: 21vmin;
  height: 15.3vmin;
  transition: all 30ms;
  position: absolute;
  transform: rotate(25deg)skew(-39deg);
  box-shadow: 1.2vmin 1.2vmin #ffffffa6;
}

.directionalButton .moveNorthImage {
  bottom: -4.5vmin;
  left: -4.5vmin;
}

.directionalButton .moveEastImage, .directionalButton .moveSouthImage {
  bottom: -3.5vmin;
  left: -4.5vmin;
}

.directionalButton .moveWestImage {
  bottom: -4.5vmin;
  left: -4.5vmin;
}

.directionalButton:hover {
  box-shadow: 1.2vmin 1.2vmin #ffffff80;
}

.directionalButton:hover .directionalButtonImage {
  opacity: .9;
  transform: skew(39deg)rotate(-25deg)translateY(-1vmin);
}

.directionalButton:active {
  background-color: #f8c789cc;
  transform: translateY(1.5vmin)rotate(26deg)skew(-39deg);
  box-shadow: 0 0 #0000;
}

.directionalButton:active .directionalButtonImage {
  opacity: .9;
  transform: skew(39deg)rotate(-25deg);
}

.directionalButton:active #northArrowImage {
  transform: skew(39deg)rotate(-25deg)translate(3.5vmin, -3.5vmin);
}

.directionalButton:active #eastArrowImage {
  transform: skew(39deg)rotate(-25deg)translate(3.5vmin, 1vmin);
}

.directionalButton:active #southArrowImage {
  transform: skew(39deg)rotate(-25deg)translate(-3.5vmin, 1vmin);
}

.directionalButton:active #westArrowImage {
  transform: skew(39deg)rotate(-25deg)translate(-3.5vmin, -3.5vmin);
}

.directionalButtonSelected {
  filter: brightness(1.2);
}

.directionalButtonSelected .directionalButtonImage {
  transform: skew(39deg)rotate(-25deg)scale(1.1)translateY(-1vmin);
}

.directionalButtonImage {
  pointer-events: none;
  background-size: 100%;
  width: 26vmin;
  height: 26vmin;
  transition: all 40ms;
  position: absolute;
  bottom: .5vmin;
  left: -9.8vmin;
  transform: skew(39deg)rotate(-25deg);
}

#northDirectionalButton {
  bottom: 34vmin;
  right: 19vmin;
}

#eastDirectionalButton {
  bottom: 16.5vmin;
  right: 19vmin;
}

#southDirectionalButton {
  bottom: 16.5vmin;
  left: 19vmin;
}

#westDirectionalButton {
  bottom: 34vmin;
  left: 19vmin;
}

.pressEffectButton {
  -webkit-backdrop-filter: blur(3vmin);
  backdrop-filter: blur(3vmin);
  color: #002f46;
  cursor: pointer;
  background-color: #fffffff2;
  border: none;
  border-radius: 3.5vmin;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-weight: bold;
  transition: all 30ms;
  box-shadow: 0 1.2vmin #ffffffbf;
}

.itemPreview {
  pointer-events: none;
  background-color: #fff;
  border-radius: 3.5vmin;
}

.itemPreview.selectedItem {
  pointer-events: none;
  background-color: #fff;
  border-radius: 3.5vmin;
  box-shadow: 0 .7vmin #00865e66, 0 1.5vmin #00c288;
}

.unselectedButton {
  pointer-events: none;
  opacity: .25;
}

.selectedScale {
  transform: scale(1.03, 1.05);
}

.buttonOutline {
  border: .7vmin solid #0000;
}

.selectedButton {
  background-color: #fff;
  border: .7vmin solid #0ceaa5;
  box-shadow: 0 1.5vmin #0ceaa5;
}

.tabButton {
  color: #002f46;
  cursor: pointer;
  pointer-events: auto;
  background-color: #ffffffe6;
  border-bottom-right-radius: 3vmin;
  border-bottom-left-radius: 3vmin;
  width: 20vmin;
  height: 15vmin;
  transition: all 30ms;
  position: relative;
  box-shadow: 0 1vmin #ffffffbf;
}

.playerTab {
  margin-right: 1vmin;
}

.selectedTab {
  background-color: #fffffffa;
  height: 15.8vmin;
  font-weight: bold;
  box-shadow: 0 1.2vmin #d86b01e6;
}

.secondaryButton {
  -webkit-backdrop-filter: blur(3vmin);
  backdrop-filter: blur(3vmin);
  color: #fff;
  cursor: pointer;
  background-color: #001220cc;
  border: none;
  border-radius: 3vmin;
  width: 100%;
  padding-top: 1.2vmin;
  padding-bottom: 1.3vmin;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-weight: bold;
  transition: all 30ms;
}

.largeButtonText {
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  font-size: 7vmin;
}

.tabButtonText {
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 1.7vmin;
  margin-left: .5vmin;
  margin-right: .5vmin;
  font-size: 5vmin;
  overflow: hidden;
}

.characterSelectButtonText {
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 3.7vmin;
  font-size: 5.5vmin;
  position: absolute;
  left: 15vmin;
  right: 2vmin;
  overflow: hidden;
}

.characterSelectButtonText.nonCharacterButtonText {
  margin-top: 2.8vmin;
  font-size: 5.5vmin;
  right: 9vmin;
}

.smallButtonText {
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  font-size: 4.5vmin;
}

.nameHeaderText {
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-size: 8vmin;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.pressEffectButton:hover {
  box-shadow: 0 1.2vmin #ffffff8c;
}

.selectedButton:hover {
  box-shadow: 0 1.5vmin #0ceaa58c;
}

.pressEffectButton:active {
  transform: translateY(1.5vmin);
  box-shadow: 0 0 #0000;
}

.bootButton {
  border-radius: 5.5vmin;
  margin-bottom: 2.2vmin !important;
}

.bootButton:hover {
  box-shadow: 0 1.2vmin #ff7979b3;
}

.bootButton:active {
  transform: translateY(1.5vmin);
  box-shadow: 0 0 #0000;
}

.tabButton:hover {
  box-shadow: 0 1vmin #ffffff8c;
}

.tabButton:active {
  transform: translateY(1vmin);
  box-shadow: 0 0 #0000;
}

.selectedTab:hover {
  box-shadow: 0 1.2vmin #fac78bcc;
}

.selectedTab:active {
  transform: translateY(1.2vmin);
  box-shadow: 0 0 #0000;
}

.secondaryButton:hover, .secondaryButton:active {
  background-color: #001220;
}

.pressEffectButtonSmall:hover {
  box-shadow: 0 .8vmin #ffffff8c;
}

.pressEffectButtonSmall:active {
  transform: translateY(.8vmin);
  box-shadow: 0 0 #0000;
}

.mainButtonSpacing {
  margin-top: 1.5vmin;
  margin-bottom: 1.5vmin;
  padding: 2.5vmin 5vmin;
}

.smallButtonSpacing {
  margin-top: 1vmin;
  margin-bottom: 1vmin;
  padding: 1.5vmin 4vmin;
}

.optionsButtonSpacing {
  padding: .5vmin;
}

.actionInfoContainer {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.titleText {
  color: #fff;
  text-shadow: 0 0 2px #000;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 1.8vmin;
  margin-bottom: .8vmin;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-size: 7vmin;
  font-weight: bold;
}

.infoText {
  color: #fff;
  text-shadow: 0 0 2px #000;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-size: 4vmin;
  font-weight: bold;
}

.actionButtonInnerText {
  color: #fff;
  vertical-align: text-bottom;
  -webkit-user-select: none;
  user-select: none;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-size: 4vmin;
  font-weight: bold;
  position: absolute;
  bottom: 1vmin;
  left: 1vmin;
  right: 1vmin;
}

.actionLabelText {
  color: #fff;
  text-shadow: 0 0 2px #000;
  -webkit-user-select: none;
  user-select: none;
  width: 22vmin;
  margin-top: -.1vmin;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-size: 4vmin;
  font-weight: bold;
  position: absolute;
}

.actionNumberText {
  color: #fff;
  text-shadow: 0 0 2px #000;
  -webkit-user-select: none;
  user-select: none;
  width: 5.8vmin;
  height: 6vmin;
  margin: 0;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-size: 5vmin;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.tutorialText {
  margin-bottom: -1vmin;
  padding-top: .2vmin;
}

.writerCharacterNameText {
  z-index: 2;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  border-bottom-right-radius: 3vmin;
  border-bottom-left-radius: 3vmin;
  width: 90vmin;
  margin-top: -9vmin;
  padding-left: 4vmin;
  padding-right: 4vmin;
  font-size: 6vmin;
  overflow: hidden;
}

.playerNameListElement {
  background: linear-gradient(#0b172380, #0b1723);
  width: 88vmin;
  height: 10vmin;
}

.playerNameText {
  color: #fff;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  width: 70vmin;
  margin: 0 0 0 1vmin;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-size: 5vmin;
  font-weight: bold;
  overflow: hidden;
}

.smallText {
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-size: 4vmin;
  font-weight: normal;
}

.packagePanelText {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 12vmin;
  padding: 1vmin;
  display: flex;
}

.joinFormField {
  color: #002f46;
  text-align: center;
  border: none;
  border-radius: 2vmin;
  width: 80%;
  height: 5vmin;
  padding: 3vmin;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-size: 7vmin;
  font-weight: bold;
}

.writerInputField {
  color: #002f46;
  border-radius: 2vmin;
  width: 90%;
  height: 34vmin;
  margin-top: 2vmin;
  margin-bottom: 2vmin;
  padding: 3vmin;
  font-family: Atkinson Hyperlegible, sans-serif;
  font-size: 7vmin;
  font-weight: bold;
  line-height: 8vmin;
}

.socialButton {
  width: 11vmin;
  height: 11vmin;
  padding: 1.5vmin;
  transition: all 30ms;
}

.socialButton:hover {
  opacity: .9;
  transform: scale(1.05);
}

.socialButton:active {
  transform: scale(.98);
}

.verticalCentreLayout {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.horizontalPanelLayout {
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2.5vmin;
  display: flex;
}

.adaptivePanelLayout {
  justify-content: center;
  align-items: center;
  gap: 2.5vmin;
  display: flex;
}

.adaptiveInputPhaseLayout {
  justify-content: center;
  align-items: center;
  display: flex;
}

.verticalPanelLayout {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5vmin;
  display: flex;
}

.actionButtonLayout {
  flex-flow: wrap;
  place-content: center;
  gap: 3vmin;
  display: flex;
}

.actionButtonSquareLayout {
  flex-flow: wrap;
  place-content: center;
  gap: 3vmin;
  width: 80vmin;
  height: 80vmin;
  display: flex;
}

.actionButton {
  text-align: center;
  cursor: pointer;
  background-color: #001220cc;
  border: .4vmin solid #000;
  border-radius: 1.5vmin;
  flex-shrink: 0;
  width: 22vmin;
  height: 22vmin;
  transition: all 30ms;
  position: relative;
  overflow: hidden;
}

.actionButton:hover {
  filter: brightness(1.1);
}

.actionButton:active {
  opacity: .8;
  transform: scale(.9);
}

.actionButtonRound {
  text-align: center;
  cursor: pointer;
  background-color: #001220cc;
  border: .4vmin solid #000;
  border-radius: 50%;
  width: 22vmin;
  height: 22vmin;
  transition: all 30ms;
  position: relative;
}

.actionButtonInner {
  background-color: #ffffff4d;
  border: .4vmin solid #000;
  border-radius: 1vmin;
  position: absolute;
  inset: 1vmin;
}

.actionButtonRound .actionButtonInner {
  border-radius: 50%;
}

.actionButtonInnerTextLayout {
  flex-direction: column;
  place-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.actionButtonWithLabel {
  height: 25.8vmin;
}

.actionButtonWithLabel .actionButtonInner {
  top: 4.8vmin;
}

.actionButtonPreviewMove {
  background-color: #019748;
}

.actionButtonPreviewMove .actionButtonInner {
  background-color: #bdffdc;
}

.actionButtonPreviewInteract {
  background-color: #c86400;
}

.actionButtonPreviewInteract .actionButtonInner {
  background-color: #ffd9b3;
}

.actionButtonPreviewWait {
  background-color: #2397e4;
}

.actionButtonPreviewWait .actionButtonInner {
  background-color: #b9e3ff;
}

.actionButtonPreviewThrow {
  background-color: #e8d734;
}

.actionButtonPreviewThrow .actionButtonInner {
  background-color: #fffdc0;
}

.actionButtonSelected {
  border: .4vmin solid #fff;
}

.actionButtonSelected .actionButtonInner {
  border-color: #fff;
}

.actionButtonItemPosition {
  background-color: #00865e;
}

.actionButtonItemPosition .actionButtonInner {
  background-color: #00c288;
}

.disabledButton {
  opacity: .3;
  pointer-events: none;
}

.configButton {
  width: 25vmin;
  height: 25vmin;
}

.characterSelectButton {
  width: 46%;
  height: 14vmin;
  margin-top: 1vmin;
  position: relative;
  overflow: hidden;
}

.characterSelectButton.nonCharacterButton {
  width: 95%;
  height: 12vmin;
}

.characterSelectButtonImage {
  -webkit-user-select: none;
  user-select: none;
  background-size: 100%;
  width: 18vmin;
  height: 18vmin;
  position: absolute;
  bottom: -2vmin;
  left: -3vmin;
}

.characterSelectButtonImage.nonCharacterButtonImage {
  width: 16vmin;
  height: 16vmin;
  bottom: -2vmin;
  left: -2.5vmin;
}

.writerCharacterButton {
  scroll-snap-align: center;
  display: grid;
}

.placeholderButton {
  cursor: auto;
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
}

.actionNumberImage {
  -webkit-user-select: none;
  user-select: none;
  width: 6vmin;
  height: 6vmin;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.actionNumberImageSmall {
  -webkit-user-select: none;
  user-select: none;
  width: 5vmin;
  height: 5vmin;
  display: block;
  position: relative;
  top: -14.6vmin;
  left: -1vmin;
}

.packagePanel {
  width: 45vmin;
  height: 36vmin;
  padding: 0;
}

.packagePanel.sidePackagePanel {
  opacity: .75;
  transform: scale(.9);
}

.arrowButton {
  cursor: pointer;
  background-image: url("Icon_Arrow1_128.63da18a0.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: content-box;
  width: 16vmin;
  height: 16vmin;
  padding: 5vmin;
  transition: all 30ms;
}

.arrowButton.rightArrow {
  transform: scaleX(-1);
}

.arrowButton:hover {
  opacity: .75;
}

.arrowButton:active {
  transform: scale(.8);
}

.arrowButton.rightArrow:active {
  transform: scale(-.8, .8);
}

.characterScrollSection {
  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;
  scroll-behavior: smooth;
  -webkit-mask-image: -webkit-linear-gradient(left, #0000 0%, #000 10% 90%, #0000 100%);
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #00122066;
  grid-auto-flow: column;
  gap: 2vmin;
  width: 96vmin;
  margin-top: .8vmin;
  padding-top: 1.5vmin;
  padding-bottom: 9vmin;
  display: grid;
  overflow-x: auto;
  overflow-y: hidden;
  mask-image: linear-gradient(90deg, #0000 0%, #000 10% 90%, #0000 100%);
}

.characterScrollSection::-webkit-scrollbar {
  display: none;
}

.overlay {
  position: fixed;
  inset: 0%;
}

.defaultBlur {
  -webkit-backdrop-filter: blur(4vmin);
  backdrop-filter: blur(4vmin);
  background-color: #001b2966;
}

.darkBlur {
  -webkit-backdrop-filter: blur(1vmin);
  backdrop-filter: blur(1vmin);
  background-color: #001220cc;
}

.buttonPromptAnimated {
  animation: .2s ease-in forwards buttonPromptAnimation;
}

@keyframes buttonPromptAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: .9;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fadeInAnimated {
  animation: .2s .15s backwards fadeAnimation;
}

.fadeInQuickAnimated {
  animation: .1s backwards fadeAnimation;
}

@keyframes fadeAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.applyScaleMult {
  transform: scale(var(--scaleMult));
}

.applyScaleMultY {
  transform: scaleY(var(--scaleMult));
}

.applyScaleMultX {
  transform: scaleX(var(--scaleMult));
}

.playerImage {
  -webkit-user-select: none;
  user-select: none;
  background-size: cover;
  width: 16vmin;
  height: 16vmin;
}

#menuImage {
  background-image: url("Icon_Menu_128.b797e9f0.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 68%;
  height: 68%;
  position: relative;
  inset: 25% 16% 7%;
}

.tabPlayerImage {
  -webkit-user-select: none;
  user-select: none;
  background-size: cover;
  width: 10vmin;
  height: 10vmin;
  position: absolute;
  bottom: .4vmin;
  left: .8vmin;
}

.tabReadyImage {
  -webkit-user-select: none;
  user-select: none;
  background-size: cover;
  width: 9vmin;
  height: 9vmin;
  position: absolute;
  bottom: .6vmin;
  right: .3vmin;
}

@media (any-pointer: fine), (pointer: fine) {
  ::-webkit-scrollbar {
    width: 4vmin;
    height: 4vmin;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 1.6vmin;
  }

  ::-webkit-scrollbar-thumb {
    background: #00172366;
    border: .5vmin solid #fff;
    border-radius: 1.5vmin;
    width: 3vmin;
    height: 3vmin;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #00172380;
  }

  ::-webkit-scrollbar-thumb:active {
    background: #00172399;
  }
}

@media (orientation: portrait) {
  html {
    --scaleMult: 1;
  }

  .spacerInPortrait {
    width: 2vmin;
    height: 2vmin;
  }

  .multiturnHeader {
    height: 15%;
  }

  .belowHeaderContent {
    top: 15%;
  }

  .adaptivePanelLayout {
    flex-direction: column;
  }

  .adaptiveInputPhaseLayout {
    flex-direction: column;
    gap: 0;
  }

  .actionInfoContainer {
    width: 75vmin;
  }

  .actionButtonLayout {
    width: 80vmin;
    height: 80vmin;
  }

  .hideInPortrait {
    display: none;
  }
}

@media (aspect-ratio >= 12 / 18) {
  html {
    --scaleMult: .86;
  }
}

@media (aspect-ratio >= 14 / 18) {
  html {
    --scaleMult: .7;
  }
}

@media (aspect-ratio >= 16 / 18) {
  html {
    --scaleMult: .62;
  }
}

@media (orientation: landscape) {
  html {
    --scaleMult: .5;
  }

  #gameInputMainLayout {
    align-items: flex-start;
  }

  #actionSelectButtonsPanel {
    z-index: 1;
  }

  #directionHeading {
    width: 81vmin;
    margin-left: 8.5vmin;
  }

  #directionButtonsLayout {
    width: 85vmin;
    transform: translate(2vmin, -24.5vmin);
  }

  #playerPreviewGrid {
    margin-left: 0;
  }

  .spacerInLandscape {
    width: 2vmin;
    height: 2vmin;
  }

  .readyButtonActionInput {
    margin-top: -25vmin !important;
    margin-left: 66vmin !important;
  }

  .unreadyButtonActionInput {
    margin-top: -25vmin !important;
    margin-left: 110vmin !important;
  }

  .readyButtonWriterTextInput {
    margin-top: -2vmin !important;
  }

  .multiturnHeader {
    height: 20%;
  }

  .belowHeaderContent {
    top: 20%;
  }

  .writerTextInputContent {
    top: 5% !important;
  }

  .adaptivePanelLayout {
    flex-direction: row;
  }

  .adaptiveInputPhaseLayout {
    flex-direction: row;
    align-items: flex-start;
  }

  .actionInfoContainer {
    width: 80vmin;
    height: 16vmin;
  }

  .actionButtonLayout {
    width: 165vmin;
    height: 40vmin;
  }

  .actionButtonPanel {
    margin-bottom: 0;
  }

  .writerInputField {
    height: 8vmin !important;
  }

  .hideInLandscape {
    display: none;
  }
}

@media (aspect-ratio >= 18 / 16) {
  html {
    --scaleMult: .55;
  }
}

@media (aspect-ratio >= 18 / 13) {
  html {
    --scaleMult: .72;
  }
}

@media (aspect-ratio >= 18 / 11) {
  html {
    --scaleMult: .85;
  }
}

/*# sourceMappingURL=GamePad.a27db045.css.map */
